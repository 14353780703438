import React, { useEffect, useState } from 'react';
import { Select, Row, Col, Card } from 'antd';
import ReactPlayer from 'react-player/youtube';
import api from 'services/api';
import topImage from '../../assets/img/jpg/background_macro.jpg'
import macroTv from '../../assets/img/png/macro_tv_logo.png'
import { VideoItemInterface } from 'interfaces/youtubeInterface';

const { Option } = Select;

const VideoGalleryMacro: React.FC = () => {

  const [videos, setVideos] = useState<VideoItemInterface[]>([]);

  useEffect(() => {
    api.get('/videos').then((response) => {
      setVideos(response.data);
    });
  }, []);

  return (
    <div style={{ padding: 40, margin: '0 auto', marginTop: 60 }}>
      
      <div style={{ 
        position: 'relative', 
        width: '100%', 
        height: '200px',
        overflow: 'hidden',
        borderRadius: 10,
        marginBottom: 20
      }}>
        
        <img 
          src={topImage} 
          style={{ 
            width: '100%', 
            height: '100%',
            position: 'absolute', 
            left: 0, 
            top: 0, 
            zIndex: 1,
            objectFit: 'cover'
          }} 
        />

        <img 
          src={macroTv} 
          style={{ 
            position: 'absolute', 
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
            width: 400,
            maxWidth: '80%',
            maxHeight: '80%'
          }} 
        />

      </div>

      {/* Lista Responsiva de Vídeos usando Row e Col */}
      <Row gutter={[16, 16]}>
        {videos.map((video) => (

          <Col xs={24} sm={12} md={8} lg={6} key={video.videoId}>
            <Card
              title={video.title}
              hoverable
              style={{
                borderRadius: 8,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >

              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${video.videoId}`} // URL do YouTube
                controls={true}
                width="100%"
                height="250px"
                playing={false}
                light={video?.thumbnail?.url} // Exibe o thumbnail enquanto o vídeo não for iniciado
                config={{
                  embedOptions: {
                    youtube: {
                      playerVars: { 
                        disablekb: 1,
                        modestbranding: 1,
                        rel: 0,
                      },
                    },
                  }
                }}
              />

                {/* Exibe a descrição abaixo do título */}
                {/* 
                  <p style={{ marginTop: 10, fontSize: '14px', color: '#555' }}>
                  {video.description ? video.description : "Sem descrição disponível"}
                  </p>
                */}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default VideoGalleryMacro;
