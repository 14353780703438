import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Form, Input, Select, message, Divider } from "antd";
import api from "services/api"; // Certifique-se de que a API está corretamente importada
import * as XLSX from 'xlsx';

const { Option } = Select;

interface User {
  key: string;
  fullName: string;
  email: string;
  phone: string;
  accessLevel: string; // A = Administrador, L = Lead, C = Cliente
  origin: string
  createdAt: Date
}

const buttonStyleAdd = {
    color: '#fff',
    marginLeft: 10,
    fontFamily: 'Arial, sans-serif', // Adicionando a fonte desejada
    fontSize: '16px', // Definindo o tamanho da fonte
    fontWeight: 'normal', // Definindo o peso da fonte
    height: 35,
    backgroundColor: '#323e56'
  };

const buttonStyleCancel = {
    marginLeft: 10,
    fontFamily: 'Arial, sans-serif', // Adicionando a fonte desejada
    fontSize: '16px', // Definindo o tamanho da fonte
    fontWeight: 'normal', // Definindo o peso da fonte
    height: 35
};

const LeadsController = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState(""); // Estado para armazenar o valor de pesquisa

  // Função para buscar usuários
  const fetchUsers = async () => {
    try {
      const response = await api.get("/leads");
      setUsers(response.data);
      setFilteredUsers(response.data); // Inicializa com todos os usuários
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Função para abrir o modal
  const openModal = (user?: User) => {
    if (user) {
      setEditingUser(user);
      form.setFieldsValue(user); // Preencher o formulário com os dados do usuário
    } else {
      setEditingUser(null);
      form.resetFields(); // Limpar o formulário
    }
    setModalVisible(true);
  };

  // Função para salvar o usuário
  const handleSubmit = async (values: User) => {
    try {
      if (editingUser) {
        await api.put(`/users/${editingUser.key}`, values); // Editar usuário existente
        message.success("Usuário atualizado com sucesso!");
      } else {

          // Prepare os dados para a API
        const dataToSubmit = {
            ...values,
            origin: 'Adm',  // Adicionando o campo 'origin'
            lead: false,    // Adicionando o campo 'lead'
        };
        
        await api.post("/users", dataToSubmit); // Adicionar novo usuário
        message.success("Usuário adicionado com sucesso!");
      }
      setModalVisible(false);
      fetchUsers(); // Atualizar lista de usuários
    } catch (error) {
      console.error("Erro ao salvar o usuário:", error);
      message.error("Erro ao salvar o usuário.");
    }
  };

  // Função para excluir o usuário
  const handleDelete = async (key: string) => {
    try {
      await api.delete(`/users/${key}`);
      message.success("Usuário excluído com sucesso!");
      fetchUsers(); // Atualizar lista de usuários
    } catch (error) {
      console.error("Erro ao excluir o usuário:", error);
      message.error("Erro ao excluir o usuário.");
    }
  };

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).replace(',', ' -');
  };

  // Função para aplicar o filtro
  const handleSearch = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = users.filter(user => 
      user.fullName.toLowerCase().includes(lowercasedSearchTerm) ||
      user.email.toLowerCase().includes(lowercasedSearchTerm) ||
      user.phone.toLowerCase().includes(lowercasedSearchTerm) ||
      user.origin.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredUsers(filtered);
  };

  const columns = [
    {
      title: "Data Cadastro",
      dataIndex: "createdAt",
      render: (text: string) => formatDateTime(text),
    },
    {
      title: "Nome",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
    },
    {
      title: "Origem",
      dataIndex: "origin",
    },
    {
      title: "Ação",
      render: (text: any, user: User) => (
        <>
          <Button style={{ backgroundColor: '#323e56' }} onClick={() => openModal(user)}>Editar</Button>
          <Button onClick={() => handleDelete(user.key)} style={{ marginLeft: 10 }}>
            Excluir
          </Button>
        </>
      ),
    },
  ];

  const exportarParaExcel = () => {
    // Cria uma nova planilha
    const ws = XLSX.utils.json_to_sheet(filteredUsers);
    
    // Cria um novo arquivo Excel
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Leads');

    // Gera o arquivo Excel e inicia o download
    XLSX.writeFile(wb, 'leads.xlsx');
  };

  return (
    <div className="adm-report-file-upload-container">
      <h2 style={{ textAlign: 'left', fontStyle: 'italic' }}>Gerenciar Leads</h2>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Campo de Pesquisa */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            placeholder="Pesquisar por qualquer coluna"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '400px', marginBottom: 10, height: 35 }}
          />
          <Button 
            onClick={handleSearch}
            style={{ backgroundColor: '#323e56', color: '#fff', marginLeft: 10 }}
          >
            Filtrar
          </Button>
        </div>

        {/* Botão Gerar Relatório XLSX */}
        <Button
          style={{
            backgroundColor: '#323e56',
            color: '#fff',
            position: 'absolute',
            marginTop: 15,
            right: 60
          }}
          onClick={() => exportarParaExcel()}
        >
          Gerar Relatório XLSX
        </Button>
      </div>

      <Table
        dataSource={filteredUsers?.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)}
        columns={columns}
        rowKey="key" // Use uma chave única para cada linha
        pagination={{ pageSize: 10 }} // Personalize a paginação conforme necessário
      />

    <Modal
        title={
          <div
            style={{
              backgroundColor: '#323e56',
              color: '#fff',
              width: '98.2%',
              position: "absolute",
              left: 0,
              top: 0,
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 10,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              textAlign: "center"
            }}
          >
            <span>{editingUser ? "Editar Lead" : "Adicionar Usuário"}</span>
          </div>
        }    
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        centered // Centraliza o modal
        >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Nome"
            name="fullName"
            rules={[{ required: true, message: "Por favor, insira o nome!" }]}
            style={{ marginBottom: -50, marginTop: 30}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Por favor, insira o email!" }]}
            style={{ marginBottom: -50}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[{ required: true, message: "Por favor, insira o telefone!" }]}
            style={{ marginBottom: -50}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Origem"
            name="origin"
            rules={[{ required: true, message: "Por favor, insira a origem!" }]}
          >
            <Select placeholder="Selecione a origem" style={{ width: '100%' }}>
              <Option value="Adm">Admin</Option>
              <Option value="Lead">Lead</Option>
              <Option value="Cliente">Cliente</Option>
            </Select>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={buttonStyleAdd}
          >
            {editingUser ? "Salvar alterações" : "Adicionar Lead"}
          </Button>
          <Button
            onClick={() => setModalVisible(false)}
            style={buttonStyleCancel}
          >
            Cancelar
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default LeadsController;
