import React, { useEffect, useState } from "react";
import { Input, Button, List, Layout, message as antdMessage } from "antd";
import api from "services/api";
import TextArea from "antd/es/input/TextArea";

const { Sider, Content } = Layout;

interface User {
  key: string;
  fullName: string;
  email: string;
  phone: string;
  accessLevel: string;
  origin: string;
  createdAt: Date;
}

interface Message {
  id: string;
  userSender: string;
  message: string;
  phone: string;
  macro: string
  createdAt: Date;
}

const WhatsappController = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");

  const fetchUsers = async () => {
    try {
      const response = await api.get("/leads");
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  const fetchMessages = async (phone: string) => {
    try {
      const response = await api.get(`/whatsapp/messages/${phone}`);
      setMessages(response.data);
    } catch (error) {
      console.error("Erro ao buscar mensagens:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const interval = setInterval(() => {
        fetchMessages(selectedUser.phone);
      }, 20000); // Atualiza a cada 20 segundos

      return () => clearInterval(interval); // Limpa o intervalo ao desmontar
    }
  }, [selectedUser]);

  const formatDateTime = (dateTime: Date) => {
    const date = new Date(dateTime);
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).replace(",", " -");
  };

  const handleSearch = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = users.filter((user) =>
      user.fullName.toLowerCase().includes(lowercasedSearchTerm) ||
      user.phone.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredUsers(filtered);
  };

  const handleUserClick = (user: User) => {
    setSelectedUser(user);
    fetchMessages(user.phone);
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      antdMessage.warning("Digite uma mensagem antes de enviar.");
      return;
    }

    if (!selectedUser) {
      antdMessage.warning("Selecione um lead antes de enviar uma mensagem.");
      return;
    }

    try {
      const payload = {
        to: selectedUser.phone,
        template_name: "template_inicial",
        paramA: newMessage,
      };

      await api.post(`/whatsapp/sendSingleMessage`, payload);
      setNewMessage("");
      antdMessage.success("Mensagem enviada com sucesso!");

      // Recarregar mensagens após enviar
      fetchMessages(selectedUser.phone);
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);
      antdMessage.error("Erro ao enviar mensagem.");
    }
  };

  return (
    <Layout style={{ height: "90vh", border: "1px solid #ddd" }}>
      <Sider width={300} style={{ background: "#f0f2f5", overflowY: "auto" }}>
        <div style={{ padding: "10px" }}>
          <Input
            placeholder="Pesquisar Lead"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onPressEnter={handleSearch}
            style={{ marginBottom: 10 }}
          />
          <Button
            onClick={handleSearch}
            style={{ backgroundColor: "#323e56", color: "#fff", width: "100%" }}
          >
            Filtrar
          </Button>
        </div>
        <List
          dataSource={filteredUsers}
          renderItem={(user) => (
            <List.Item
              style={{
                cursor: "pointer",
                padding: "10px",
                backgroundColor:
                  selectedUser?.key === user.key ? "#d3e4ff" : "transparent",
              }}
              onClick={() => handleUserClick(user)}
            >
              <List.Item.Meta title={user.fullName} description={user.phone} />
            </List.Item>
          )}
        />
      </Sider>
      <Content style={{ padding: "10px", background: "#fff" }}>
        {selectedUser ? (
          <>
            <div style={{ marginBottom: "10px" }}>
              <h2>{selectedUser.fullName}</h2>
            </div>
            <div
              style={{
                height: "calc(90vh - 180px)",
                overflowY: "auto",
                border: "1px solid #ddd",
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#edeadd",
                borderRadius: 10,
              }}
            >
              {messages.map((message) => (
                <div
                  key={message.id}
                  style={{
                    textAlign: message.macro === "S" ? "right" : "left",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "8px",
                      backgroundColor:
                        message.macro === "S" ? "#daf0d8" : "#f1f1f1",
                    }}
                  >
                    <p style={{ margin: 0 }}>{message.message}</p>
                    <small style={{ color: "#888" }}>
                      {formatDateTime(message.createdAt)}
                    </small>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ display: "flex" }}>
              <TextArea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Digite sua mensagem"
                rows={3}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault()
                    handleSendMessage();
                  }
                }}
                style={{
                  backgroundColor: "#f9f9f9",
                  resize: "none",
                }}
              />
              <Button
                onClick={handleSendMessage}
                style={{
                  backgroundColor: "#323e56",
                  color: "#fff",
                  marginLeft: "10px",
                  marginTop: 4,
                  height: 70
                }}
              >
                Enviar
              </Button>
            </div>
          </>
        ) : (
          <p>Selecione um lead para visualizar as mensagens.</p>
        )}
      </Content>
    </Layout>
  );
};

export default WhatsappController;
