import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Skeleton, Statistic } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, BarChart } from 'recharts';
import { CheckCircleOutlined, UserAddOutlined, ExceptionOutlined, RubyOutlined, WarningOutlined, CommentOutlined, UsergroupAddOutlined, TagsOutlined, TeamOutlined, MailOutlined, FileOutlined } from '@ant-design/icons';
import api from '../../../services/api';

// Interfaces para tipagem
interface MessageData {
  date: string;
  total: string;
}

interface DashboardData {
  totalLeadsPendentes: number;
  totalLeadsConvertidos: number;
  totalLeadsCadastrados: number;
  leadsByDay: MessageData[];
  totalComunidade: number;
  totalContato: number;
  totalRelatorio: number;
}

const DashboardController: React.FC = () => {

  const [data, setData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  // Função para buscar dados da API
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/dashboard');
      setData(response.data);
    } catch (error) {
      setError('Erro ao buscar dados');
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoading(false);
    }
  };

  // Chama a função fetchData quando o componente é montado
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div><Skeleton active /></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Função para criar a série de dados para o gráfico
  const createChartData = () => {
    const chartData: { date: string; sent: number }[] = [];
    
    // Loop através do mês atual
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= totalDaysInMonth; day++) {
      const date = new Date(year, month, day);
      const formattedDate = `${String(day).padStart(2, '0')}/${String(month + 1).padStart(2, '0')}`;
      const messageEntry = data?.leadsByDay.find(
        (entry) => new Date(entry.date).getDate() === day && new Date(entry.date).getMonth() === month
      );

      chartData.push({
        date: formattedDate,
        sent: messageEntry ? parseInt(messageEntry.total, 10) : 0,
      });
    }

    return chartData;
  };

  const chartData = createChartData();


  const conversionRate = data?.totalLeadsCadastrados
    ? ((data.totalLeadsConvertidos / data.totalLeadsCadastrados) * 100).toFixed(2)
    : 0;

  const averageLeadsPerDay = data?.leadsByDay.length
    ? (data.totalLeadsCadastrados / data.leadsByDay.length).toFixed(2)
    : 0;    
    
  const daysWithoutLeads = chartData.filter((entry) => {
    const [day, month] = entry.date.split('/').map(Number);
    const today = new Date();
    const isPastOrToday = day <= today.getDate() && month === today.getMonth() + 1;
  
    return entry.sent === 0 && isPastOrToday;
  }).length;
  
  return (
    <div style={{ padding: '30px' }}>
      <h2 style={{ textAlign: 'left', fontStyle: 'italic' }}>Dashboard</h2>
      
      {/* Campanhas Section */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f5f8ff' }}>
            <Statistic
              title="Leads Pendentes"
              value={data?.totalLeadsPendentes}
              valueStyle={{ color: '#1890ff' }}
              prefix={<RubyOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
            <Card style={{ backgroundColor: '#f5fff5' }}>
            <Statistic
                title="Leads Convertidos"
                value={data?.totalLeadsConvertidos}
                valueStyle={{ color: '#32a852' }}
                prefix={<UsergroupAddOutlined />}
                style={{ fontWeight: 'bold'}}
                />
            </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#fff9eb' }}>
            <Statistic
              title="Leads Cadastrados"
              value={data?.totalLeadsCadastrados || 0}
              valueStyle={{ color: '#3246a8' }}
              prefix={<UserAddOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f0f9ff' }}>
            <Statistic
              title="Taxa de Conversão"
              value={`${conversionRate}%`}
              valueStyle={{ color: '#1a8cff' }}
              prefix={<CheckCircleOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#fff7e6' }}>
            <Statistic
              title="Leads Médios por Dia"
              value={averageLeadsPerDay}
              valueStyle={{ color: '#fa8c16' }}
              prefix={<UserAddOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#ffedf0' }}>
            <Statistic
              title="Dias sem Leads"
              value={daysWithoutLeads}
              valueStyle={{ color: '#ff4d4f' }}
              prefix={<WarningOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
      </Row>  

      <Divider /> 

      <h2 style={{ fontStyle: 'oblique' }}>Origem dos Leads</h2>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f0f9ff' }}>
            <Statistic
              title="Contato"
              value={`${data?.totalContato}`}
              valueStyle={{ color: '#1a8cff' }}
              prefix={<MailOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f0f9ff' }}>
            <Statistic
              title="Comunidade"
              value={data?.totalComunidade}
              valueStyle={{ color: '#1a8cff' }}
              prefix={<TeamOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f0f9ff' }}>
            <Statistic
              title="Relatórios"
              value={data?.totalRelatorio}
              valueStyle={{ color: '#1a8cff' }}
              prefix={<FileOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
      </Row>      

      <Divider />

      <h2 style={{ fontStyle: 'oblique' }}>Leads Cadastrados por Dia</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="sent" stroke="#8884d8" activeDot={{ r: 8 }} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DashboardController;
