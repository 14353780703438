import React, { useEffect, useState } from "react";
import axios from 'axios';
import logoMacro from '../../assets/img/png/macro_azul.png'; 
import excelIcon from '../../assets/img/png/excel.png';
import wordIcon from '../../assets/img/png/word.png';
import pdfIcon from '../../assets/img/png/pdf.png';
import './Reports.css';
import api from "services/api";
import { decryptData } from "utils/cript";
import LoginModal from "components/LoginModal";
import noImage from '../../assets/img/jpg/noimage.jpg'

interface Report {
    key: string;
    avatar: string;
    title: string;
    description: string;
    fileName: string;
    fileType: string;
    url: string;
    deletedAt: string
}

const Reports = () => {
    
    const [reports, setReports] = useState<Report[]>([]);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const getIconForFileType = (fileType: string) => {
        switch (fileType.toLowerCase()) {
            case 'xlsx':
            case 'xls':
                return excelIcon;
            case 'docx':
            case 'doc':
                return wordIcon;
            case 'pdf':
                return pdfIcon;
            default:
                return ''; 
        }
    };

    useEffect(() => {
        const fetchReports = async () => {
            try {
                // Buscando os dados já com os links seguros na API
                const response = await api.get('/reports');
                setReports(response.data);
            } catch (error) {
                console.error("Erro ao buscar relatórios:", error);
            }
        };

        fetchReports();

        const tkn = localStorage.getItem('tkn')

        if ( tkn === null || tkn === '' ){
            setIsLoginModalOpen(true)
        }

    }, []);

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);

        const tkn = localStorage.getItem('tkn')

        if ( tkn === null || tkn === '' ){
            window.location.href = '/';
        }
    };


    const handleLoginSuccess = (token: string) => {
        localStorage.setItem('tkn', token);
        setIsLoginModalOpen(false)
    };

    const handleDownload = async (url: string, fileName: string, key: string) => {

        const tkn = localStorage.getItem('tkn')

        if ( tkn === null || tkn === '' ){
            window.location.href = '/';
            return
        }

        try {

            const urlHistory = "/reports/history"
            
            await api.post(urlHistory, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                reportKey: key || ''
            })

            // Fazendo a solicitação para a URL do arquivo
            const response = await fetch(decryptData(url), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });
    
            // Verifica se a resposta foi bem-sucedida
            if (!response.ok) {
                throw new Error('Erro ao baixar o arquivo.');
            }
    
            const blob = await response.blob(); // Cria um blob com o conteúdo do arquivo
            const link = document.createElement('a'); // Cria um link temporário
            link.href = window.URL.createObjectURL(blob); // Cria um URL para o blob
            link.download = fileName; // Define o nome do arquivo a ser baixado
            document.body.appendChild(link); // Adiciona o link ao DOM
            link.click(); // Simula o clique no link para forçar o download
            document.body.removeChild(link); // Remove o link do DOM
            window.URL.revokeObjectURL(link.href); // Libera a URL do blob

            
        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
        }
    };

    return (
        <div className="reports-container">
            <img src={logoMacro} alt="Logo Macro" className="reports-logo" />
            <h2>Relatórios</h2>

            {reports.length === 0 ? (
                <p className="no-reports-message">Não temos relatórios para serem exibidos no momento.</p>
            ) : (
                <div className="reports-list">
                    {reports?.filter((f)=>f.deletedAt === null ).map((report) => (
                        <div key={report.key} className="report-card">
                            <img 
                                src={report.avatar !== null ? decryptData(report.avatar) : noImage} 
                                alt={report.fileType} 
                                className="report-icon" 
                                style={{ 
                                    width: 200,
                                    borderRadius: 10
                                }}
                            />
                            <h2 className="report-name">{report.title}</h2>
                            <p className="report-description">{report.description}</p>
                            <button onClick={() => handleDownload(report.url, report.fileName, report.key)} className="download-button">
                                Baixar
                            </button>
                        </div>
                    ))}
                </div>
            )}

            <LoginModal
                isOpen={isLoginModalOpen}
                onClose={closeLoginModal}
                onLoginSuccess={handleLoginSuccess}
            />
        </div>
    );
};

export default Reports;
