import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Form, Input, Select, message, Divider } from "antd";
import api from "services/api"; // Certifique-se de que a API está corretamente importada
import TextArea from "antd/es/input/TextArea";
import { leadStagesInterface } from "interfaces/leadsStagesInterface";

const { Option } = Select;

const buttonStyleAdd = {
    color: '#fff',
    marginLeft: 10,
    fontFamily: 'Arial, sans-serif', // Adicionando a fonte desejada
    fontSize: '16px', // Definindo o tamanho da fonte
    fontWeight: 'normal', // Definindo o peso da fonte
    height: 35,
    backgroundColor: '#323e56'
  };

const buttonStyleCancel = {
    marginLeft: 10,
    fontFamily: 'Arial, sans-serif', // Adicionando a fonte desejada
    fontSize: '16px', // Definindo o tamanho da fonte
    fontWeight: 'normal', // Definindo o peso da fonte
    height: 35
};

const LeadStagesController = () => {
  const [stages, setStages] = useState<leadStagesInterface[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingStage, setEditingStage] = useState<leadStagesInterface | null>(null);
  const [form] = Form.useForm();

  // Função para buscar usuários
  const fetchUsers = async () => {
    try {
      const response = await api.get("/leadstagegetall");
      setStages(response.data);
    } catch (error) {
      console.error("Erro ao buscar estágios dos leads:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Função para abrir o modal
  const openModal = (stage?: leadStagesInterface) => {
    if (stage) {
      setEditingStage(stage);
      form.setFieldsValue(stage); // Preencher o formulário com os dados do estágio
    } else {
      setEditingStage(null);
      form.resetFields(); // Limpar o formulário
  
      // Calcula o próximo valor de stageOrder
      const maxStageOrder = stages.length > 0 
        ? Math.max(...stages.map(stage => stage.stageOrder)) 
        : 0;
  
      form.setFieldsValue({ stageOrder: maxStageOrder + 1 });
    }
    setModalVisible(true);
  };

  // Função para salvar o usuário
  const handleSubmit = async (values: leadStagesInterface) => {
    try {
      if (editingStage) {
        await api.put(`/alterleadstage/${editingStage.id}`, values); // Editar usuário existente
        message.success("Estágio atualizado com sucesso!");
      } else {

          // Prepare os dados para a API
        const dataToSubmit = {
            ...values
        };
        
        await api.post("/createleadstage", dataToSubmit); // Adicionar novo usuário
        message.success("Estágio adicionado com sucesso!");
      }
      setModalVisible(false);
      fetchUsers(); // Atualizar lista de usuários
    } catch (error) {
      console.error("Erro ao salvar o Estágio:", error);
      message.error("Erro ao salvar o Estágio.");
    }
  };

  // Função para excluir o usuário
  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/deleteleadstage/${id}`);
      message.success("Estágio excluído com sucesso!");
      fetchUsers(); // Atualizar lista de usuários
    } catch (error) {
      console.error("Erro ao excluir o Estágio:", error);
      message.error("Erro ao excluir o Estágio.");
    }
  };

  /*
  const columns = [
    {
      title: "Nome do Estágio",
      dataIndex: "stageName",
    },
    {
      title: "Ordem",
      dataIndex: "stageOrder",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Dias Próximo Estágio",
      dataIndex: "daysToNextStage",
    },
    {
      title: "Ação",
      render: (text: any, user: leadStagesInterface) => (
        <>
          <Button style={{ backgroundColor: '#323e56' }} onClick={() => openModal(user)}>Editar</Button>
          <Button onClick={() => handleDelete(user.id)} style={{ marginLeft: 10 }}>
            Excluir
          </Button>
        </>
      ),
    },
  ];
  */

  const columns = [
    {
      title: "Nome do Estágio",
      dataIndex: "stageName",
    },
    {
      title: "Ordem",
      dataIndex: "stageOrder",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Dias Próximo Estágio",
      dataIndex: "daysToNextStage",
    },
    {
      title: "Ação",
      render: (text: any, stage: leadStagesInterface) => (
        <>
          <Button
            style={{
              backgroundColor: stage.id === 1 ? "#ccc" : "#323e56",
              color: stage.id === 1 ? "#666" : "#fff",
            }}
            onClick={() => stage.id !== 1 && openModal(stage)}
            disabled={stage.id === 1}
          >
            Editar
          </Button>
          <Button
            onClick={() => handleDelete(stage.id)}
            style={{
              marginLeft: 10,
              backgroundColor: stage.id === 1 ? "#ccc" : undefined,
              color: stage.id === 1 ? "#666" : undefined,
            }}
            disabled={stage.id === 1}
          >
            Excluir
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="adm-report-file-upload-container">
      <h2 style={{ textAlign: 'left', fontStyle: 'italic' }}>Gerenciar Estágios</h2>
      <Button
        style={{ backgroundColor: '#323e56', color: '#fff', position: 'absolute', marginTop: -40, right: 60 }}
        onClick={() => openModal()}
      >
        Adicionar Estágio
      </Button>

      <Table
        dataSource={stages}
        columns={columns}
        rowKey="key" // Use uma chave única para cada linha
        pagination={{ pageSize: 10 }} // Personalize a paginação conforme necessário
      />

    <Modal
        title={
          <div
            style={{
              backgroundColor: '#323e56',
              color: '#fff',
              width: '98.2%',
              position: "absolute",
              left: 0,
              top: 0,
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 10,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              textAlign: "center"
            }}
          >
            <span>{editingStage ? "Editar Estágio" : "Adicionar Estágio"}</span>
          </div>
        }
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        centered
        closeIcon={false}
        >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Nome do Estágio"
            name="stageName"
            rules={[{ required: true, message: "Por favor, insira o nome do estágio!" }]}
            style={{ marginBottom: -50, marginTop: 30}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ordem do Estágio"
            name="stageOrder"
            rules={[{ required: true, message: "Por favor, insira a ordem do estágio!" }]}
            style={{ marginBottom: -30}}
          >
            <Input type="number" style={{ height: 40 }} />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[{ required: true, message: "Por favor, insira a Descrição!" }]}
            style={{ marginBottom: -50}}
          >
            <TextArea rows={5} />
          </Form.Item>

          <Form.Item
            label="Dias para o Próximo Estágio"
            name="daysToNextStage"
            rules={[{ required: true, message: "Por favor, insira a quantidade de dias para próxima etapa!" }]}
            style={{ marginBottom: -30}}
          >
            <Input type="number" style={{ height: 40 }} />
          </Form.Item>

          <Divider />

          <Form.Item style={{ marginBottom: -50, textAlign: 'right' }}>
            
            <Button
              style={buttonStyleCancel}
              onClick={() => {
                setModalVisible(false);
                form.resetFields();
              }}
            >
              Cancelar
            </Button>

            <Button type="primary" htmlType="submit" style={buttonStyleAdd}>
              {editingStage ? "Salvar" : "Adicionar"}
            </Button>

          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LeadStagesController;
