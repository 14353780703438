import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../assets/img/png/LogoMacro.png';
import Modal from 'components/Modal';
import Squad from 'components/Squad';
import LoginModal from 'components/LoginModal';
import CadForm from 'components/CadForm';

import AvatarImg from '../../assets/img/png/finiteLogo.png';

import { Avatar } from 'antd';

interface NavbarProps {
    scrollToProducts: () => void;
    scrollToContact: () => void;
    scrollToMacro: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ scrollToProducts, scrollToContact, scrollToMacro }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCadOpen, setIsModalCadOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [token, setToken] = useState<string | null>(null);
    const [isAdm, setIsAdm] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false); // Submenu state

    useEffect(()=>{
        const tkn = localStorage.getItem('tkn')
        
        if ( tkn !== ''  && tkn !== null ){
            setToken('ok')
        }else{
            setToken('')
        }

        const level = localStorage.getItem('n')

        if ( level === 'A' ){
            setIsAdm(true)
        }else{
            setIsAdm(false)
        }

    },[])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenuOnScroll = () => {
        if (window.innerWidth <= 768) {
            setIsMenuOpen(false);
        }
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', closeMenuOnScroll);
        return () => {
            window.removeEventListener('scroll', closeMenuOnScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleLoginSuccess = (token: string) => {
        localStorage.setItem('tkn', token);

        const level = localStorage.getItem('n')

        if ( level === 'A' ){
            setIsAdm(true)
            window.location.href = '/adm/main';
        }else{
            setIsAdm(false)
            window.location.href = '/reports';
        }
        
    };

    const handleMacro = () => {
        window.location.href = '/macro';
    };

    const handleReports = () =>{
        window.location.href = '/reports';
    }

    const handleLogoff = () => {
        setToken('')
        localStorage.removeItem('tkn')
        localStorage.removeItem('n')
        window.location.href = '/';
    };

    const handleCad = async () => {
        setIsModalCadOpen(true);
    };

    const closeModalCad = () => {
        setIsModalCadOpen(false);
    };

    const handleToAdm = () => {
        window.location.href = '/adm/main';
    }

    // Função para navegar e rolar até os produtos
    const handleScrollToProducts = () => {
        // Redireciona para a página inicial
        window.location.href = '/';
        // Aguarda um pouco para garantir que a página carregue antes de rolar
        setTimeout(() => {
            scrollToProducts(); // Chama a função de rolagem para os produtos
        }, 100); // 100ms pode ser ajustado conforme necessário
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <a href="/">
                        <img src={logo} alt="Logo Macro" />
                    </a>
                </div>

                <div className="navbar-button-member">
                    <a
                        href="https://portal.eqi.com.br/cadastro/?assessor=2601474"
                        target='_blank'
                        rel="noopener noreferrer"
                        style={{
                            backgroundColor: "#fff",
                            borderRadius: 10,
                            padding: '10px 20px',
                            color: '#323e56',
                            fontSize: 14,
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}>
                        Abrir Conta
                    </a>
                </div>

                <ul className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
                    
                    <li><a onClick={handleScrollToProducts} style={{ cursor: 'pointer' }} >Nossos Produtos</a></li>
                    <li><a onClick={handleMacro} style={{ cursor: 'pointer' }} >Macro</a></li>
                    <li><a href="#team" style={{ cursor: 'pointer' }} onClick={openModal}>Nossa Equipe</a></li>

                    <li>
                        <div
                            className="submenu-container"
                            onMouseEnter={() => setIsSubmenuOpen(true)}
                            onMouseLeave={() => setIsSubmenuOpen(false)}
                        >
                            <a style={{ cursor: 'pointer' }}>Materiais</a>
                            {isSubmenuOpen && (
                                <ul className="submenu">
                                {token ? (
                                    <li>
                                        <a style={{ cursor: 'pointer' }} onClick={()=>handleReports()}>Relatórios</a>
                                    </li>
                                ) : (
                                    <li>
                                        <a style={{ cursor: 'pointer' }} onClick={handleCad}>Relatórios</a>
                                    </li>
                                )}
                                    <li><a onClick={() => window.location.href = '/videos'}>Vídeos</a></li>
                                </ul>
                            )}
                        </div>
                    </li>

                    <li><a onClick={scrollToContact} style={{ cursor: 'pointer' }} >Contato</a></li>

                    <div className="navbar-buttons">
                        <a
                            href="https://portal.eqi.com.br/cadastro/?assessor=2601474"
                            target='_blank'
                            rel="noopener noreferrer"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: 10,
                                padding: '10px 20px',
                                color: '#323e56',
                                fontSize: 14,
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                textDecoration: 'none'
                            }}>
                            Abrir Conta
                        </a>
                    </div>

                    {token ? (
                        <li>
                            <a 
                                onClick={handleLogoff} 
                                style={{ cursor: 'pointer',
                                        backgroundColor: "#3d84b8",
                                        borderRadius: 10,
                                        padding: '10px 20px',
                                        color: '#fff',
                                        fontSize: 14,
                                        fontWeight: 'bolder' 
                                    }}
                                >Sair</a>
                        </li>
                    ) : (
                        <li>
                            <a 
                                onClick={() => setIsLoginModalOpen(true)} 
                                style={{ cursor: 'pointer', 
                                        backgroundColor: "#3d84b8", 
                                        borderRadius: 10, 
                                        padding: '10px 20px', 
                                        color: '#fff', 
                                        fontSize: 14, 
                                        fontWeight: 'bolder' 
                                    }}
                                >Entrar</a>
                        </li>
                    )}

                    { isAdm === true ? (
                        <li>
                            <Avatar 
                                src={AvatarImg}
                                style={{ 
                                backgroundColor: '#c6c6c6',
                                width: 40,
                                height: 40,
                                marginTop: -10,
                                cursor: 'pointer'
                                }}
                            onClick={()=>handleToAdm()}
                            />
                        </li>
                    ) : (<></>) }
                </ul>

                <div className="hamburger" onClick={toggleMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </div>

            {/* Modal */}
            <Modal isOpen={isModalOpen} onClose={closeModal} title="">
                <Squad />
            </Modal>

            {/* Login Modal */}
            <LoginModal
                isOpen={isLoginModalOpen}
                onClose={closeLoginModal}
                onLoginSuccess={handleLoginSuccess}
            />

            {/* Modal */}
            <CadForm
                isOpen={isModalCadOpen}
                onClose={closeModalCad}
                origin={'Relatorio'}
            />
        </nav>
    );
};

export default Navbar;
