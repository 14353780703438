import React from 'react';

const MacroCalendar = () => {
  return (
    <div>
      <h2>Agenda Macro</h2>
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FSao_Paulo&title=Agenda%20Macro&src=bWFjcm8uYXNzZXNzb3JpYS5haUBnbWFpbC5jb20&src=ZW4udXNhLm9mZmljaWFsI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%230B8043"
        width="100%" 
        height="900" 
        title="Agenda Macro"
        frameBorder="0" 
      ></iframe>
    </div>
  );
};

export default MacroCalendar;