import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { leadStagesInterface } from 'interfaces/leadsStagesInterface';
import api from 'services/api';
import { leadsInterface } from 'interfaces/leadsInterface';

const KanbanBoard = () => {
  const [stagesData, setStagesData] = useState<leadStagesInterface[]>([]);
  const [leadsData, setLeadsData] = useState<leadsInterface[]>([]);
  const [searchText, setSearchText] = useState(""); // Estado para o texto de pesquisa

  const pastelColors = [
    '#c5e0e6',
    '#e6d5c5',
    '#d2e6c5',
    '#c5cde6',
    '#d4c5e6',
    '#c5e6df',
  ];

  const fetchData = async () => {
    try {
      const responseStages = await api.get("/leadstagegetall");
      setStagesData(responseStages.data);

      const responseLeads = await api.get("/leads");
      setLeadsData(responseLeads.data);
    } catch (error) {
      console.error("Erro ao buscar estágios dos leads:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formatPhone = (phone: string) => {
    const cleaned = phone.replace(/\D/g, "");
    const match =
      phone.length === 12
        ? cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/)
        : cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phone;
  };

  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    const updatedLeads = [...leadsData];
    const leadIndex = updatedLeads.findIndex(
      (lead) => lead.id === parseInt(result.draggableId)
    );

    if (leadIndex >= 0) {
      const lead = updatedLeads[leadIndex];
      const newStepId = parseInt(destination.droppableId);

      updatedLeads[leadIndex].currentStageId = newStepId;
      setLeadsData(updatedLeads);

      try {
        await api.post(`/leads/kanban/${lead.key}`, {
          newStepId: newStepId,
        });
        console.log(`Lead ${lead.id} atualizado para o estágio ${newStepId}`);
      } catch (error) {
        console.error(
          `Erro ao atualizar o lead ${lead.id} para o estágio ${newStepId}:`,
          error
        );
      }
    }
  };

  // Filtra os leads com base no texto de pesquisa
  const filteredLeads = searchText
    ? leadsData.filter(
        (lead) =>
          lead.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
          lead.phone.includes(searchText)
      )
    : leadsData;

  return (
    <>
      {/* Input de pesquisa */}
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
        <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Pesquisar leads..."
          style={{
            padding: "8px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginRight: "8px",
            width: "50%",
          }}
        />
      </div>

      {/* Kanban */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ display: 'flex', width: '100%' }}>
          {stagesData.map((stage, index) => (
            <Droppable key={stage.id} droppableId={stage.id.toString()}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    flex: 1,
                    margin: 8,
                    padding: 8,
                    backgroundColor: pastelColors[index % pastelColors.length],
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 200,
                  }}
                >
                  <h3 style={{ textAlign: 'center' }}>{stage.stageName}</h3>
                  {filteredLeads
                    .filter((lead) => lead.currentStageId === stage.id)
                    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                    .map((lead, index) => (
                      <Draggable key={lead.id} draggableId={lead.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              padding: 8,
                              margin: 4,
                              backgroundColor: '#ffffff',
                              borderRadius: '4px',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <p style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                              {lead.fullName}
                            </p>
                            <p style={{ marginBottom: '4px' }}>
                              {formatPhone(lead.phone)}
                            </p>
                            <p style={{ fontSize: '12px', color: '#555' }}>
                              {formatDateTime(lead.createdAt)}
                            </p>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default KanbanBoard;
